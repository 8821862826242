import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"

/* Assets */
import { ThemeProvider } from "@material-ui/styles"
import theme from "../constants/Theme"
import Page from "../components/page"

export default (props) => {
  const { data: {page, settings}, location } = props;

  return(
    <ThemeProvider theme={theme}>
      <Layout page={page} widgets={page.widgets}>
        <Page page={page} widgets={page.widgets} location={location} settings={settings} />
      </Layout>
    </ThemeProvider>
  )
}

export const query = graphql`
  query PageQuery($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      ...Page
    }
    settings: datoCmsSetting {
        ...SettingQuery
    }
  }
`
